import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Row, Modal, Table, Popover, OverlayTrigger } from 'react-bootstrap'
import Strings from '../assets/strings/Strings.json'
import DatePicker from "react-datepicker";
import CustSelectLabel from "../components/SelectLabel";
import Form from 'react-bootstrap/Form';
import * as constants from "../utils/constants";
import * as alerts from "../utils/Messages";
import RESPONSE_CODE from '../utils/ResponseCode'
import { useDispatch, useSelector } from "react-redux";
import { _listProxyAddress, _vendorListProductMessageList, _profileSubscriptionInfo, _selectedOfferList, _listOfferRequests } from "../actions/VendorAction";
import ZctaAnalysis from '../components/ZctaAnalysis';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SelectedOffers from '../components/ProductSelected';
import NoImage from "../assets/img/no-image.svg"
import InnerLayout from "../components/InnerLayout";
import { toast } from 'react-toastify';
import { _listProductToApp, _scheduleTimeList } from '../actions/ProductAction';
import { getTimeFrame } from '../helpers/validationHelper';
import Paginate from '../components/Paginate'

const Broadcast = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  // vendor list proxy and zipcodes start
  const listProxyAddress = (params) => dispatch(_listProxyAddress(params));
  const listProxyAddressResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.listProxyApiResponse
  );
  // vendor list proxy and zipcodes ends

  // Message list start
  const listProductMessageList = () =>
    dispatch(_vendorListProductMessageList());
  const listProductMessageListResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.listProductMessageListResponse
  );

  // Product list start
  const listProducts = (details) => dispatch(_listProductToApp(details));
  const listProductToAppResponse = useSelector(
    (RootReducer) => RootReducer.ProductReducer.listProductToAppResponse
  );
  // Product list ends

  // Product list start
  const listScheduleTime = (details) => dispatch(_scheduleTimeList(details));
  const listScheduleTimeResponse = useSelector(
    (RootReducer) => RootReducer.ProductReducer.scheduleTimeListResponse
  );
  // Product list ends

  // vendor subscription info Details start
  const subscriptionInfo = () => dispatch(_profileSubscriptionInfo());
  const subscriptionInfoResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.subscriptionInfoApiResponse
  );

  // selected offer list start
  const selectedOfferList = (params) => dispatch(_selectedOfferList(params));
  const selectedOfferListResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.selectedOfferListResponse
  );

  // listed request offers start
  const listOfferRequest = (params) => dispatch(_listOfferRequests(params));
  const listOfferRequestResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.listOfferRequestsResponse
  );
  const initialOfferRequestParams = {
    offerTypeId: null,
    subCategoryId: null,
    searchTxt:"",
    p_PageNumber: 1,
    p_PageSize: 4,
    productId: ""
  };
  const [messages, setMessages] = useState([]);
  const [messageDescription, setMessageDescription] = useState("");
  const [messageId, setMessageId] = useState(0);
  const [DayOptions, setDayOptions] = useState([]);
  const [zipcodeList, setZipcodeList] = useState([]);
  const [checked, setChecked] = useState({});
  const [addressValue, setAddressValue] = useState({
    message: "",
    errorMessage: "",
  });
  const [date, setDate] = useState({
    startDay: 1,
    endDay: 30,
  });
  const [optionsAddress, setOptionsAddress] = useState([
    { value: 1, label: "Default Address" },
    { value: 2, label: "Proxy Zipcode" },
    { value: 3, label: "Proxy Address" },
  ]);
  const [zctaZipCode, setZctaZipCode] = useState("");
  const [proxyAddress, setProxyAddress] = useState({
    type: { value: 1, label: "Default Address" },
    zip_codes: [],
    proxy_details: [],
  });
  const [messagesMaster, setMessagesMaster] = useState([]);
  const [showSchedule, setShowSchedule] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [scheduleTimeList, setScheduleTimeList] = useState([]);
  const [isClickdedDoneListMessage, seIsClickdedDoneListMessage] =
    useState(false);
  const [currentPlan, setCurrentPlan] = useState(0);
  const [type, setType] = useState(0);
  const [selectedList, setSelectedList] = useState([]);
  const [offersList, setOffersList] = useState([]);
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(0);
  const [offersListLoad, setOffersListLoad] = useState(false);
  const [offerRequestParams, setOfferRequestParams] = useState(initialOfferRequestParams);

  const [scheduledata, setScheduledata] = useState({
    start_date: new Date(),
    end_date: new Date(),
    occurrence: 0,
    value: [],
    date: 0,
    time: "",
    startTime: "",
    endTime: "",
    timeZone: "",
  });


  useEffect(() => {
    if (listProxyAddressResponse && listProxyAddressResponse.apiStatus) {
      setProxyAddress((proxyAddress) => ({
        ...proxyAddress,
        zip_codes: [],
      }));
      setAddressValue((addressValue) => ({
        ...addressValue,
        message: "",
        errorMessage: "",
      }));
      setZipcodeList([]);
      setZctaZipCode("");
      if (
        listProxyAddressResponse.apiErrorCode === constants.STATUS_200 &&
        listProxyAddressResponse.apiResponse &&
        listProxyAddressResponse.apiResponse.proxy_info
      ) {
        if (
          proxyAddress.type.value === constants.STATUS_1 &&
          listProxyAddressResponse.apiResponse.proxy_info[0]
        ) {
          let address = `${
            listProxyAddressResponse.apiResponse.proxy_info[0]?.address1 || ""
          }
          ${
            listProxyAddressResponse.apiResponse.proxy_info[0]?.city
              ? ", " + listProxyAddressResponse.apiResponse.proxy_info[0]?.city
              : ""
          }
           ${
             listProxyAddressResponse.apiResponse.proxy_info[0]?.statename
               ? ", " +
                 listProxyAddressResponse.apiResponse.proxy_info[0]?.statename
               : ""
           }
           ${
             listProxyAddressResponse.apiResponse.proxy_info[0]?.zip
               ? ", " + listProxyAddressResponse.apiResponse.proxy_info[0]?.zip
               : ""
           }
           ${
             listProxyAddressResponse.apiResponse.proxy_info[0]?.countryname
               ? ", " +
                 listProxyAddressResponse.apiResponse.proxy_info[0]?.countryname
               : ""
           }`;
          setAddressValue((addressValue) => ({
            ...addressValue,
            message: address,
          }));
          let zipcode_to_timezone = require("zipcode-to-timezone");
          let timeZone = zipcode_to_timezone.lookup(
            listProxyAddressResponse.apiResponse.proxy_info[0]?.zip
          );
          setProxyAddress((proxyAddress) => ({
            ...proxyAddress,
            zip_codes: listProxyAddressResponse.apiResponse.proxy_info[0]?.zip,
            proxy_details: [
              {
                zipcode:
                  listProxyAddressResponse.apiResponse.proxy_info[0]?.zip,
                timeZone: timeZone === null ? "Asia/Kolkata" : timeZone,
              },
            ],
          }));
          setZctaZipCode([
            listProxyAddressResponse.apiResponse.proxy_info[0]?.zip,
          ]);
        } else if (
          proxyAddress.type.value === 3 &&
          listProxyAddressResponse.apiResponse.proxy_info[0]
        ) {
          let address = "";
          if (
            listProxyAddressResponse.apiResponse.proxy_info[0].address === ""
          ) {
            address = "";
            setAddressValue((addressValue) => ({
              ...addressValue,
              errorMessage: RESPONSE_CODE[1312],
            }));
          } else {
            address =
              listProxyAddressResponse.apiResponse.proxy_info[0]?.address.trim() +
              ", " +
              listProxyAddressResponse.apiResponse.proxy_info[0]?.city.trim() +
              ", " +
              listProxyAddressResponse.apiResponse.proxy_info[0]?.state.trim() +
              ", " +
              listProxyAddressResponse.apiResponse.proxy_info[0]?.zip.trim() +
              ", " +
              listProxyAddressResponse.apiResponse.proxy_info[0]?.country_name.trim();
          }
          let zipcode_to_timezone = require("zipcode-to-timezone");
          let timeZone = zipcode_to_timezone.lookup(
            listProxyAddressResponse.apiResponse.proxy_info[0]?.zip
          );
          setProxyAddress((proxyAddress) => ({
            ...proxyAddress,
            zip_codes: listProxyAddressResponse.apiResponse.proxy_info[0]?.zip,
            proxy_details: [
              {
                zipcode:
                  listProxyAddressResponse.apiResponse.proxy_info[0]?.zip,
                timeZone: timeZone === null ? "Asia/Kolkata" : timeZone,
              },
            ],
          }));
          setAddressValue((addressValue) => ({
            ...addressValue,
            message: address,
          }));
          setZctaZipCode([
            listProxyAddressResponse.apiResponse.proxy_info[0]?.zip.trim(),
          ]);
        } else if (
          proxyAddress.type.value === constants.STATUS_2 &&
          listProxyAddressResponse.apiResponse.proxy_info
        ) {
          let data = listProxyAddressResponse.apiResponse.proxy_info;
          let zipArray = [];
          let zipcode = [];
          data.map((data) => {
            zipArray.push({
              value: data.zip_id,
              label: data.zip_code,
            });
            zipcode.push(data.zip_code);
          });
          setZipcodeList(zipArray);
          setZctaZipCode(zipcode);
          return;
        }
      } else if (
        listProxyAddressResponse.apiErrorCode === constants.STATUS_400
      ) {
        setAddressValue((addressValue) => ({
          ...addressValue,
          errorMessage: listProxyAddressResponse.apiMessage,
        }));
      }
    }
  }, [listProxyAddressResponse]);

  useEffect(() => {
    if (messagesMaster) {
      var messageArray = [];
      messagesMaster.map((messages) => {
        messageArray.push({
          value: messages.message_id,
          label: messages.message_title,
        });
      });
      setMessages(messageArray);
    }
  }, [messagesMaster]);

  useEffect(() => {
    listProxyAddress({
      proxy_type: proxyAddress.type.value,
    });
  }, [proxyAddress.type]);

  useEffect(() => {
    if (currentPlan === constants.STATUS_2) {
      setOptionsAddress([
        { value: 1, label: "Default Address" },
        { value: 2, label: "Proxy Zipcode" },
      ]);
    } else {
      setOptionsAddress([
        { value: 1, label: "Default Address" },
        { value: 2, label: "Proxy Zipcode" },
        { value: 3, label: "Proxy Address" },
      ]);
    }
  }, [currentPlan]);

  useEffect(() => {
    if (isClickdedDoneListMessage) {
      seIsClickdedDoneListMessage(false);
      setMessageDescription("");
      setMessageId(0);
    }
  }, [isClickdedDoneListMessage]);

  useEffect(() => {
    const today = new Date();
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 1);
    const lastDayOfMonth = new Date(nextMonth - 1);
    setScheduledata((scheduledata) => ({
      ...scheduledata,
      end_date: lastDayOfMonth,
    }));
  }, [showSchedule]);

  useEffect(() => {
    const list = [];
    for (let i = date.startDay; i <= date.endDay; i++) {
      const day = { value: i, label: i };
      list.push(day);
    }
    setDayOptions(list);
  }, [date]);

  useEffect(() => {
    getSmallestDayInMonths(scheduledata.start_date, scheduledata.end_date);
  }, [scheduledata.start_date, scheduledata.end_date]);

  useEffect(() => {
    listProductMessageList();
    listScheduleTime();
    subscriptionInfo();
    if(location.state?.checkedItems){
      const arr = location.state?.checkedItems
      const numberArray = arr.map(Number);

      let payload ={
        productIds: numberArray
      }
      setCheckedItems(location.state?.checkedItems)      
      selectedOfferList(payload)
    } 
    if(location.state?.type){
      setType(location.state?.type)
    }
  }, []);
  //handle List your product message list API response
  useEffect(() => {
    if (
      listProductMessageListResponse &&
      listProductMessageListResponse.apiStatus
    ) {
      if (listProductMessageListResponse.apiErrorCode === constants.STATUS_200)
        if (listProductMessageListResponse.apiResponse.messages_info) {
          setMessagesMaster(
            listProductMessageListResponse.apiResponse.messages_info
          );
        }
    }
  }, [listProductMessageListResponse]);
   //handle List your product message list API response
   useEffect(() => {
    if (
      selectedOfferListResponse &&
      selectedOfferListResponse.apiStatus
    ) {
      if (selectedOfferListResponse.apiErrorCode === constants.STATUS_200){
        setSelectedList(selectedOfferListResponse.apiResponse?.productList)

      }
        resetResponse("selectedOfferList")
    }
  }, [selectedOfferListResponse]);

  //handle List your offers request list API response
  useEffect(() => {      
    if (
      listOfferRequestResponse &&
      listOfferRequestResponse.apiStatus
    ) {
      if (listOfferRequestResponse.apiErrorCode === constants.STATUS_200){
        setOffersList(listOfferRequestResponse.apiResponse?.offerRequestList)
      } else {
        setOffersList([])
      }
        // resetResponse("listOfferRequests")
        setOffersListLoad(false)

    }
  }, [listOfferRequestResponse]);

  //handle List product to APP API response
  useEffect(() => {
    if (listProductToAppResponse && listProductToAppResponse.apiStatus) {
      if (listProductToAppResponse.apiErrorCode === constants.STATUS_200) {
        toast.success(listProductToAppResponse.apiMessage);
        seIsClickdedDoneListMessage(true);
        setProxyAddress((proxyAddress) => ({
          ...proxyAddress,
          type: { value: 1, label: "Default Address" },
          zip_codes: [],
          proxy_details: [],
        }));
        setScheduledata((scheduledata) => ({
          ...scheduledata,
          value: [],
          occurrence: 0,
          start_date: new Date(),
          end_date: new Date(),
          time: "",
          startTime: "",
          endTime: "",
          timeZone: "",
        }));
        setShowSchedule(false);
        navigate("/dashboard");

      } else {
        toast.error(listProductToAppResponse.apiMessage);
      }

      resetResponse("listProductToAppResponse");
    }
  }, [listProductToAppResponse]);

  const resetResponse = (action) => {
    dispatch({
      type: action,
      apiStatus: false,
      apiErrorCode: 0,
      apiResponse: {},
      apiMessage: "",
    });
  };

  //handle category list API response
  useEffect(() => {
    if (listScheduleTimeResponse && listScheduleTimeResponse.apiStatus) {
      if (listScheduleTimeResponse.apiErrorCode === constants.STATUS_200)
        if (listScheduleTimeResponse.apiResponse.time_slots) {
          let timeArray = [];
          listScheduleTimeResponse.apiResponse.time_slots.map((time) => {
            timeArray.push({
              value: {
                start: time.timeslot_from,
                end: time.timeslot_to,
                meridiem: time.meridiem,
              },
              label: `${time.timeslot_from}${time.meridiem} - ${time.timeslot_to}${time.meridiem}`,
            });
          });
          setScheduleTimeList(timeArray);
        } else toast.error(listScheduleTimeResponse.apiMessage);
    }
  }, [listScheduleTimeResponse]);
  
  // handling subscription info Response
  useEffect(() => {
    if (
      subscriptionInfoResponse &&
      subscriptionInfoResponse.apiStatus &&
      subscriptionInfoResponse.apiErrorCode === constants.STATUS_200
    ) {
      if (
        subscriptionInfoResponse.apiResponse &&
        subscriptionInfoResponse.apiResponse.subscription_info
      ) {
        setCurrentPlan(
          subscriptionInfoResponse.apiResponse.subscription_info
            ?.subscription_type
        );
      }
    }
  }, [subscriptionInfoResponse]);
  const getSmallestDayInMonths = (month1, month2) => {
    const startMonth = month1.getMonth();
    const endMonth = month2.getMonth();
    const startYear = month1.getFullYear();
    const endYear = month2.getFullYear();
    const startdayNumber = month1.getDate();
    const enddayNumber = month2.getDate();
    const diffInMillis = Math.abs(Date.parse(month1) - Date.parse(month2));
    const diffInDays = Math.floor(diffInMillis / (1000 * 60 * 60 * 24));
    const diffInMonths =
      (month2.getFullYear() - month1.getFullYear()) * 12 +
      (month2.getMonth() - month1.getMonth());
    const lastDayOfMonth = new Date(
      month2.getFullYear(),
      month2.getMonth() + 1,
      0
    );

    if (startMonth === endMonth && startYear === endYear) {
      setDate((date) => ({
        ...date,
        startDay: startdayNumber,
        endDay: enddayNumber,
      }));
    } else if (
      endMonth === (startMonth === 11 ? 0 : startMonth + 1) &&
      diffInDays < 30
    ) {
      const date = new Date(startYear, startMonth, 1);
      date.setDate(0);
      const list = [];
      for (let i = 1; i <= enddayNumber; i++) {
        const day = { value: i, label: i };
        list.push(day);
      }
      for (let i = startdayNumber; i <= date.getDate(); i++) {
        const day = { value: i, label: i };
        list.push(day);
      }
      setDayOptions(list);
    } else {
      let months = "";
      const maxDays = [];
      for (let year = startYear; year <= endYear; year++) {
        const start = year === startYear ? startMonth : 0;
        const end = year === endYear ? endMonth : 11;
        for (let month = start; month <= end; month++) {
          let daysInMonth;
          let largest;
          if (diffInDays > 60) {
            daysInMonth = getMaximumDate(year, month);
          } else {
            daysInMonth = new Date(year, month + 1, 0).getDate();
          }
          maxDays.push(daysInMonth);
          largest = Math.max(...maxDays);
          if (
            (month === end && enddayNumber === lastDayOfMonth.getDate()) ||
            (month !== end && enddayNumber !== lastDayOfMonth.getDate()) ||
            (month === 0 &&
              end === 0 &&
              enddayNumber !== lastDayOfMonth.getDate())
          ) {
            if (months === "" || largest <= months) {
              months = largest;
              setDate((date) => ({
                ...date,
                startDay: 1,
                endDay: largest,
              }));
            }
          }
        }
      }
    }
  };

  const getMaximumDate = (year, month) => {
    var nextMonthDate = new Date(year, month, 1);
    var lastDay = new Date(nextMonthDate.getTime() - 86400000);
    return lastDay.getDate();
  };

  const handleTimeRange = (e) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setScheduledata((scheduledata) => ({
      ...scheduledata,
      time: e.label,
      startTime: getTime(e.value.start, e.value.meridiem),
      endTime: getTime(e.value.end, e.value.meridiem),
      timeZone: timeZone,
    }));
  };

  const getTime = (time, type) => {
    const [hour, minute] = time.split(".");
    let newTime = new Date();
    newTime.setHours(
      parseInt(hour, 10) + (hour !== "12" && type === "PM" ? 12 : 0)
    );
    newTime.setMinutes(parseInt(minute, 10));
    newTime.setSeconds(0);
    newTime.setMilliseconds(0);
    return newTime.toLocaleTimeString([], { hour12: false });
  };

  const selectedMessage = (event) => {
    let messageIds = event.value; // {"value":4,"label":"Message 4"}

    setMessageId(messageIds);
    messagesMaster.forEach((messages) => {
      if (messages.message_id === messageIds) {
        setMessageDescription(messages.description);
      }
    });
    if(messageIds === constants.STATUS_6){
      if (selectedList.length > 0) {
        const { categoryId, subCategoryId, productId } = selectedList[0];    
        handleOfferListRequest(categoryId, subCategoryId, productId, 1, messageIds);
      }
    } else{
      setSelectedProductId(0)
      setSelectedRequests([])
    }
  };

  const selectAddress = (event) => {
    setProxyAddress((proxyAddress) => ({
      ...proxyAddress,
      type: event,
    }));
  };

  
  const closePopUp = () => {
    navigate("/dashboard");
    setMessageId(0);
    setMessageDescription("");
  };

  const handleScheduleBtn = () => {
    setShowSchedule(true);
    setScheduledata((scheduledata) => ({
      ...scheduledata,
      occurrence: 3,
      time: "",
    }));
  };

  const handleCancelSchedule = () => {
    const today = new Date();
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 1);
    const lastDayOfMonth = new Date(nextMonth - 1);
    setShowSchedule(false);
    seIsClickdedDoneListMessage(false);
    setScheduledata((scheduledata) => ({
      ...scheduledata,
      value: [],
      occurrence: 0,
      start_date: new Date(),
      end_date: lastDayOfMonth,
      date: 0,
      time: "",
      startTime: "",
      endTime: "",
      timeZone: "",
    }));
    setProxyAddress((proxyAddress) => ({
      ...proxyAddress,
      type: { value: 1, label: "Default Address" },
      zip_codes: [],
      proxy_details: [],
    }));
    setZipcodeList([]);
  };

  const handleCancel = () => {
    closePopUp();
    setProxyAddress((proxyAddress) => ({
      ...proxyAddress,
      type: { value: 1, label: "Default Address" },
      zip_codes: [],
      proxy_details: [],
    }));
    setZipcodeList([]);
    setAddressValue((addressValue) => ({
      ...addressValue,
      errorMessage: "",
    }));
  };

  const handleSheduleSubmit = () => {
    if (messageId) {
      submitScheduleList(messageId);
    }
  };

  const handleDate = (e, type) => {
    if (type === 1) {
      setScheduledata((scheduledata) => ({
        ...scheduledata,
        start_date: e,
      }));
      if (e > scheduledata.end_date) {
        setScheduledata((scheduledata) => ({
          ...scheduledata,
          end_date: e,
        }));
      }
    } else {
      setScheduledata((scheduledata) => ({
        ...scheduledata,
        end_date: e,
      }));
    }
  };

  // handle proxy checkbox
  const handleZipcodeChange = (value, zip_name) => {
    let zipcode_to_timezone = require("zipcode-to-timezone");
    let timeZone = zipcode_to_timezone.lookup(zip_name);
    if (checked.hasOwnProperty(zip_name)) {
      setChecked((checked) => ({
        ...checked,
        [zip_name]: !checked[zip_name],
      }));
    } else {
      setChecked({ ...checked, zip_name: true });
    }
    if (proxyAddress.zip_codes.includes(zip_name)) {
      let array = [...proxyAddress.zip_codes];
      let array2 = [...proxyAddress.proxy_details];
      let index = array.indexOf(zip_name);
      let index2 = array2.findIndex((item) => item.zipcode === zip_name);
      if (index !== -1) {
        array.splice(index, 1);
        array2.splice(index2, 1);
        setProxyAddress((proxyAddress) => ({
          ...proxyAddress,
          zip_codes: array,
          proxy_details: array2,
        }));
      }
    } else {
      let array = [...proxyAddress.zip_codes, zip_name];
      let array2 = [
        ...proxyAddress.proxy_details,
        {
          zipcode: zip_name,
          timeZone: timeZone === null ? "Asia/Kolkata" : timeZone,
        },
      ];
      setProxyAddress((proxyAddress) => ({
        ...proxyAddress,
        zip_codes: array,
        proxy_details: array2,
      }));
    }
  };

  const submitScheduleList = (messageId) => {
    const data = {
      product_array: checkedItems,
      messageId: messageId,
      type: type,
      isSchedule: 1,
      startdate: scheduledata.start_date,
      enddate: scheduledata.end_date,
      date: scheduledata.date,
      proxy_type: proxyAddress.type?.value,
      timeslot: scheduledata?.time,
      startTime: scheduledata?.startTime,
      endTime: scheduledata?.endTime,
      timeZone: scheduledata?.timeZone,
      proxy_details: proxyAddress?.proxy_details,
      zip_codes: proxyAddress.zip_codes,
      request_array: selectedRequests
    };    
    listProducts(data);
  };

  //List product call
  const submitListProduct = (messageId) => {
    const data = {
      product_array: checkedItems,
      messageId: messageId,
      type: type,
      proxy_type: proxyAddress.type?.value,
      zip_codes: proxyAddress.zip_codes,
      request_array: selectedRequests
    }    
    listProducts(data);
  }

  const doneListProduct = () => {
    if (messageId) {
      submitListProduct(messageId);
    }
  };

  const handleOfferListRequest = (offerTypeId,subCategoryId, productId,page, messageId) => {
    if(messageId === constants.STATUS_6){
      if(offerTypeId && subCategoryId){
        let payload = {
          offerTypeId: offerTypeId,
          subCategoryId: subCategoryId,
          searchTxt: "",
          p_PageNumber: page,
          p_PageSize:offerRequestParams.p_PageSize
      }
      setOfferRequestParams(offerRequestParams => ({...offerRequestParams, p_PageNumber: page, offerTypeId: offerTypeId, subCategoryId:subCategoryId, productId:productId }))

      listOfferRequest(payload);
      setSelectedProductId(productId);
      setOffersListLoad(true);
      }
    }
  };
// page change and state change
const pageChange = (page, type) => {
  if (page) {
    handleOfferListRequest(offerRequestParams.offerTypeId, offerRequestParams.subCategoryId,offerRequestParams.productId, page, messageId )
  }
}
  const handleRowSelect = (selectedItem) => {
    setSelectedRequests(prev => {
      const exists = prev.some(item => 
        item.requestId === selectedItem.requestId && item.productId === selectedItem.productId
      );
  
      if (exists) {
        return prev.filter(item => 
          !(item.requestId === selectedItem.requestId && item.productId === selectedItem.productId)
        );
      } else {
        return [...prev, selectedItem];
      }
    });
  };

  const handleSelectAll = () => {
    const newSelectedRequests = offersList.map(({ requestId, productId, expiryDateTime }) => ({
      requestId,
      productId: selectedProductId, // Ensure productId is assigned correctly
      expiryDateTime
    }));
  
    const isAllSelected = newSelectedRequests.every(newReq => 
      selectedRequests.some(sel => 
        sel.requestId === newReq.requestId && sel.productId === newReq.productId
      )
    );
  
    if (isAllSelected) {
      // Remove only the currently visible selected requests based on requestId & productId
      setSelectedRequests(prev => prev.filter(sel => 
        !newSelectedRequests.some(newReq => 
          newReq.requestId === sel.requestId && newReq.productId === sel.productId
        )
      ));
    } else {
      // Keep old selections and add new ones
      setSelectedRequests(prev => [
        ...prev.filter(sel => 
          !newSelectedRequests.some(newReq => 
            newReq.requestId === sel.requestId && newReq.productId === sel.productId
          )
        ), 
        ...newSelectedRequests
      ]);
    }
  };

  const productIdCount = selectedRequests.reduce((acc, item) => {
    acc[item.productId] = (acc[item.productId] || 0) + 1;
    return acc;
  }, {});

  return (
    <InnerLayout>
      <Col className='action-wrap'>
        <Row>
          <Col lg='9' >
            <h2>{Strings.BroadcastProduct.Title}</h2>
          </Col>
        </Row>
      </Col>
      <div className="inner-main broadcast-outer">
        <p className="selected-product">{Strings.BroadcastProduct.Description1}<span style={{ marginLeft: 5 }}>{checkedItems.length >= 1 ? checkedItems.length : ""}{" "}
        {checkedItems.length > 1 ? "Offers." : "Offer."}{" "} </span></p>
        <p>{Strings.BroadcastProduct.Des2P1} <span>{Strings.BroadcastProduct.Des2P2} </span>{Strings.BroadcastProduct.Des2P3}</p>
        <Row>
          <Col lg="5" xl="5">
            <CustSelectLabel
              options={messages}
              onChange={selectedMessage}
              name="message"
              placeholder={Strings.Admin.SubCategory.Modal.Placeholder3}
            />
          </Col>
          <Col lg="7" xl="7">
            {messageDescription ? <div className="info-wrap mt-1">{messageDescription} </div> : ""}
          </Col>
        </Row>
        <Row>
          <Col lg="5" xl="5">
            <CustSelectLabel
              options={optionsAddress}
              onChange={selectAddress}
              value={proxyAddress.type}
              name="message"
              placeholder={Strings.Admin.SubCategory.Modal.Placeholder3}
            />
          </Col>
          {addressValue.errorMessage && addressValue.errorMessage !== '' ? <Form.Text className='text-danger'>
            {addressValue.errorMessage}
          </Form.Text> : ""}
          <Col lg="7" xl="7">
            {addressValue.message ? (
              <div className="info-wrap mt-1">{addressValue.message}</div>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row className='proxy-pin mt-3'>
          {zipcodeList &&
            zipcodeList.map((data, key) => {
              return (
                <Col lg="3">
                  <Form.Check
                    key={[key]}
                    inline
                    label={data?.label}
                    className="check-box"
                    onChange={() =>
                      handleZipcodeChange(data?.value, data?.label)
                    }
                    checked={checked[data?.deliverytype_name]}
                  />
                </Col>
              );
            })}
        </Row>
        <Row className='mt-4'>
          <Col lg="12" xl="5" className='btn-wrap'>
            <Button variant="outline-primary" disabled={showSchedule} onClick={handleCancel}>{Strings.ListProduct.Btn1}</Button>
            <Button variant="primary" onClick={doneListProduct} disabled={showSchedule || (messageId ? false : true) || (proxyAddress.type.value === constants.STATUS_2 && proxyAddress.zip_codes.length === 0) || addressValue.errorMessage !== ""} >{Strings.BroadcastProduct.Btn3}</Button>
          </Col>
          <Col lg="12" xl="7" className='d-flex align-items-center justify-content-between schedule-info'>
            <p className='mb-0'>{Strings.ListProduct.or} <span>{Strings.ListProduct.ScheduleInfo}</span></p>
            <Button variant="primary" disabled={showSchedule || (messageId ? false : true) || (proxyAddress.type.value === constants.STATUS_2 && proxyAddress.zip_codes.length === 0) || addressValue.errorMessage !== ""} onClick={handleScheduleBtn}>{Strings.ListProduct.Btn3}</Button>
          </Col>
        </Row>
      </div>
      <div className="inner-main broadcast-outer">
        {showSchedule &&
          <div className='schedule-wrap'>
            <h3>{Strings.ListProduct.Schedule.Title2}</h3>
            <Row>
              <Col lg="3">
                <div className="date-picker">
                  <label>{Strings.ListProduct.Schedule.Label2}</label>
                  <DatePicker
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    minDate={new Date()}
                    selected={scheduledata.start_date}
                    onChange={(e) => {
                      handleDate(e, 1)
                    }}
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="date-picker">
                  <label>{Strings.ListProduct.Schedule.Label3}</label>
                  <DatePicker
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    minDate={scheduledata.start_date}
                    selected={scheduledata.end_date}
                    onChange={(e) => {
                      handleDate(e)
                    }}
                  />
                </div>
              </Col>
              <Col lg="3">
                <CustSelectLabel
                  label={"Notification Schedule Date"}
                  options={DayOptions}
                  onChange={(e) => {
                    setScheduledata((scheduledata) => ({
                      ...scheduledata,
                      date: e.value,
                    }));
                  }}
                  name="message"
                  placeholder={Strings.ListProduct.Schedule.placeholder1}
                />
              </Col>
              <Col lg="3">
                <CustSelectLabel
                  label={Strings.ListProduct.Schedule.Label1}
                  options={scheduleTimeList}
                  name="message"
                  onChange={(e) => {
                    handleTimeRange(e)
                  }}
                  placeholder={Strings.ListProduct.Schedule.placeholder}
                />
              </Col>
            </Row>


            <div className='btn-wrap second-row'>
              <Button onClick={handleCancelSchedule} variant="outline-primary">{Strings.ListProduct.Schedule.Btn1}</Button>
              <Button onClick={handleSheduleSubmit} disabled={scheduledata.occurrence === 0 || (scheduledata.occurrence === 2 && scheduledata.value.length === 0) || (scheduledata.occurrence === 3 && scheduledata.date === 0) || (messageId ? false : true) || scheduledata.time === ""} variant="primary">{Strings.ListProduct.Schedule.Btn3}</Button>
              {scheduledata.occurrence === 3 && <p> <br/>{Strings.ListProduct.Schedule.Info2}</p>}
            </div>
          </div>
        }
        <div className='zcta-data-analysis'>
          <h3>{Strings.ListProduct.Schedule.Title3}</h3>
          {/* <Col className="offer-audience-dtls d-flex justify-content-lg-between flex-lg-row flex-md-column">
            <span><label>City:</label> Detroit</span>
            <span><label>County:</label> Wayne</span>
            <span><label>State:</label> Michigan</span>
          </Col> */}
          <ZctaAnalysis zctaZipCode={zctaZipCode} listproductshow={true} />
        </div>
      </div>

      <Row className="selected-offers position-relative">
        <h3 className="mt-4">{Strings.BroadcastProduct.Title2}</h3>
        <Carousel responsive={responsive} showDots={true} renderDotsOutside={true} autoPlay={true} rewind={true} rewindWithAnimation={true} renderArrowsWhenDisabled={true}  >
        {selectedList && selectedList.length>constants.STATUS_0 &&
          selectedList.map((data, key) => {
            return (
              <SelectedOffers data={data} handleOfferListRequest={handleOfferListRequest} selectedProductId={selectedProductId} messageId={messageId}/>
            );
          })
          }
        </Carousel>
      </Row>
      {messageId === constants.STATUS_6 && 
      <>
        <p className='mb-4 mt-2 pt-3'>{Strings.BroadcastProduct.Des3}</p>
        <div className="inner-main broadcast-outer">
          <div className='broadcast-offer-list'>
            <Table responsive>
            {offersListLoad ? 
              <Col className="text-center mt-5"><div className="image-view d-flex align-items-center justify-content-center">
                <span class="spinner-border" style={{color:"#94DA0A"}}></span>
                  </div> </Col> :
                    <>
              <thead>
                <tr>
                  <th><Form.Check disabled={offersList.length === constants.STATUS_0} checked={offersList.length > 0 && productIdCount[selectedProductId] >= offersList.length && offersList.every(offer => 
                    selectedRequests.some(req => req.requestId === offer.requestId)
                  )}
                onChange={handleSelectAll}/></th>
                  <th></th>
                  <th>{Strings.BroadcastProduct.TableTitle1}</th>
                  <th>{Strings.BroadcastProduct.TableTitle2}</th>
                  <th>{Strings.BroadcastProduct.TableTitle3}</th>
                  <th>{Strings.BroadcastProduct.TableTitle4}</th>
                  <th>{Strings.BroadcastProduct.TableTitle5}</th>
                  <th>{Strings.BroadcastProduct.TableTitle6}</th>
                </tr>
              </thead>
              <tbody>
                {offersList && offersList.length > constants.STATUS_0 ? (
                  offersList.map((data, index) => (
                    <tr key={data.id || index}>
                      <td>
                        <Form.Check  checked={selectedRequests.some((item) => item.requestId === data.requestId && item.productId === selectedProductId)}
                          onChange={() => handleRowSelect({
                            requestId: data.requestId,
                            productId: selectedProductId,
                            expiryDateTime: data.expiryDateTime
                          })}/>
                      </td>
                      <td>
                        <div className="img-wrap">
                          <img src={data.imagePath ? data.imagePath : NoImage} alt="Offer" />
                        </div>
                      </td>
                      <td>{data?.offerTypeName}</td>
                      <td>{data?.subCategoryName}</td>
                      <td>{data?.offerDescription}</td>
                      <td className="text-nowrap">${data?.minPrice} - ${data?.maxPrice}</td>
                      <td>{data?.zipCode}</td>
                      <td>{data?.expectedTime && getTimeFrame(data?.expectedTime)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">{alerts.NO_OFFERS_AVAILABLE}</td>
                  </tr>
                )}
              </tbody>
              </>
              }
              
            </Table>
            {
              listOfferRequestResponse.apiStatus &&
              listOfferRequestResponse.apiResponse &&
              listOfferRequestResponse.apiResponse.pagination_info &&
              listOfferRequestResponse.apiResponse.pagination_info.total_records ? (
              <Col className="d-flex justify-content-center paginate-wrap mt-4">
                <span className="records">
                  {Strings.Pagination.Label} &nbsp;
                  {(offerRequestParams.p_PageNumber - 1) *
                    listOfferRequestResponse.apiResponse.pagination_info.per_page +
                    1}
                  &nbsp; {Strings.Pagination.Label1} &nbsp;
                  {(offerRequestParams.p_PageNumber - 1) *
                    listOfferRequestResponse.apiResponse.pagination_info.per_page +
                    listOfferRequestResponse.apiResponse.offerRequestList.length}
                  &nbsp; {Strings.Pagination.Label2} &nbsp;
                  {listOfferRequestResponse.apiResponse.pagination_info.total_records}
                  &nbsp; {Strings.Pagination.Label3}
                </span>

                <Paginate
                  totalRecords={
                    listOfferRequestResponse.apiResponse.pagination_info.total_records
                  }
                  currentPage={
                    listOfferRequestResponse.apiResponse.pagination_info.current_page
                  }
                  perPage={
                    listOfferRequestResponse.apiResponse.pagination_info.per_page
                  }
                  onClick={(page) => {
                    pageChange(page,"requests");
                  }}
                />
              </Col>
            ) : (
              ""
            )}
          </div>
        </div>
        </>
      }
    </InnerLayout>
  )
};


export default Broadcast;

