import React, { useState, useEffect } from 'react'
import './Product.scss'
import { Col } from 'react-bootstrap';
import NoImage from "../assets/img/no-image.svg"

const SelectedOffers = (props) => {
  const { data }=props  
  return (
    <Col className='product-wrap mt-0' onClick={() => props.handleOfferListRequest(data?.categoryId,data?.subCategoryId,data?.productId,1,props.messageId)}>
      <div className={`product broadcast ${props.selectedProductId === data?.productId ? "active" : ""}`}>
        <div className='img-wrap position-relative'>
          <img src={data?.imagePath ? data?.imagePath : NoImage} />
        </div>
        <span className='title d-block'>
          {data?.productName}
        </span>
        <div className='bottom-wrap d-flex justify-content-between align-items-center'>
          <span className='price text-center'>$ {data?.price}</span>
          <span className='qty'>Qty: {data?.quantity}</span>
        </div>
      </div>
    </Col>
  )
};

export default SelectedOffers;

