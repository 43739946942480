import React, { useState, useEffect } from "react";
import { Col, Row, Button, Table } from "react-bootstrap";
import Strings from "../assets/strings/Strings.json";
import CustSelect from './Select'
import NoImage from '../assets/img/no-image.svg'
import {resetResponse} from '../actions/VendorAction';
import { useDispatch } from 'react-redux';
import Input from './Input';
import SearchInput from './SearchInput';
import { getTimeFrame } from "../helpers/validationHelper";
const OfferRequestList = (props) => {
  const dispatch = useDispatch();
  const offerList = props.OfferList;
  let categoryList = props.CategoryList;
  const searchOfferRequests = props.searchOfferRequests;

  //categoryList = categoryList.slice(1);
  const subCategoryList = props.SubCategoryList;
  const [subCategories, setSubCategories] = useState([])
  const [subCategoryValue, setSubCategoriesValue] = useState("");
  const [categoryValue, setCategoriesValue] = useState({ value: 0, label: "All" });
  const [searchText, setSearchTextValue] = useState("");
  // handle Category change
  const handleCategoryChange = (value) => {
    setCategoriesValue(value)
    setSubCategoriesValue("");
    let subCategoryInfo = []
    subCategoryList.map((subCategory) => {
      if (subCategory.category_id === value.value) {
        subCategoryInfo.push({
          value: subCategory.subcategory_id,
          label: subCategory.subcategory_name,
        });
      }
    });
    //alert(JSON.stringify(subCategoryList));
    setSubCategories(subCategoryInfo);
  };

  const handleSubCategoryChange = (value) => {
    setSubCategoriesValue(value);   
  };

  const searchTextChange = (searchText) => {
    // alert(searchText)
    setSearchTextValue(searchText )
  }

  // Reset the selection when tab changes
  useEffect(() => {
    setSubCategoriesValue(""); 
    setCategoriesValue({ value: 0, label: "All" }); 
    setSearchTextValue("");
    resetResponse(dispatch,"listOfferRequests");
  }, [props.TabChange]); 

  return (
    <>
      <div>
        <Row className="offer-req-head">
          <Col lg="3">
            <CustSelect
              placeholder="Type Of Offer"
              options={categoryList}
              value={categoryValue}
              onChange={handleCategoryChange}
            />
          </Col>
          <Col lg="3">
            <CustSelect
              placeholder="Sub Category"
              options={subCategories}
              value={subCategoryValue}
              onChange={handleSubCategoryChange}
            />
          </Col>
          <Col lg="4">
            {/* <Input 
            placeholder="Search Zipcode/Description"
            type="text"
            value={searchText}
            onChange={searchTextChange}
            /> */}
             <SearchInput onClick={searchTextChange} p_SearchKey={searchText} setSearchTextValue={setSearchTextValue}/>
          </Col>
          <Col lg="2">
            <Button
              variant="primary"
              className="w-100 "
              onClick={() =>
                searchOfferRequests(
                  categoryValue.value ? categoryValue.value : null,
                  subCategoryValue.value ? subCategoryValue.value : null,
                  searchText
                )
              }
            >
              {Strings.Dashboard.OfferRequest.Btn}
              </Button>
          </Col>
        </Row>
        <Col className="inner-main offer-req">
          {offerList && offerList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>{Strings.Dashboard.OfferRequest.Th1}</th>
                  <th>{Strings.Dashboard.OfferRequest.Th2}</th>
                  <th>{Strings.Dashboard.OfferRequest.Th3}</th>
                  <th>{Strings.Dashboard.OfferRequest.Th4}</th>
                  <th>{Strings.Dashboard.OfferRequest.Th5}</th>
                  <th>{Strings.Dashboard.OfferRequest.Th6}</th>
                </tr>
              </thead>
              <tbody>
                {offerList.map((item) => {
                  return (
                    <tr>
                      <td>
                        <div className="img-wrap">
                          <img src={ item.imagePath? item.imagePath : NoImage } />
                        </div>
                      </td>
                      <td>{item.offerTypeName}</td>
                      <td>{item.subCategoryName}</td>
                      <td>{item.offerDescription}</td>
                      <td className="text-nowrap">
                        ${item.minPrice} - ${item.maxPrice}
                      </td>
                      <td>{item.zipCode}</td>
                      <td>{item.expectedTime && getTimeFrame(item.expectedTime)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <Col className="text-center mt-5">
              {Strings.Dashboard.NoOfferList}
            </Col>
          )}
        </Col>
      </div>
    </>
  );
};

export default OfferRequestList;
