/**
 * @file   src\
 * @brief  This file is responsible for 
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */
import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { Button, Col, Row, Tabs, Tab, Modal } from 'react-bootstrap'
import Add from '../assets/img/icons/Add'
import Package from '../assets/img/icons/Package'
import InnerLayout from '../components/InnerLayout'
import Product from '../components/Product'
import SearchSelect from '../components/SearchSelect'
import Paginate from '../components/Paginate'
import Strings from '../assets/strings/Strings.json'
import CustSelect from '../components/Select'
import NoImage from '../assets/img/no-image.svg'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { _vendorDashboard, _vendorCategoryList, _vendorListProductMessageList, _vendorNotificationAvailable,
         _listSchedule, _cancelSchedule, _profileSubscriptionInfo, _vendorCouponList, _vendorDeleteCoupon, _listOfferRequests } from '../actions/VendorAction';
import { _listProductToApp, _scheduleTimeList } from '../actions/ProductAction';
import { WeekChange, dateToMMDDYYYSlashFormat } from '../helpers/validationHelper'
import * as alerts from "../utils/Messages";
import * as constants from '../utils/constants';
import ListProductPopUp from '../components/ListProductPopUp'
import BroadcastProductPopUp from '../components/BroadcastProductPopUp'
import SchedulesView from '../components/SchedulesView'
import OfferRequestList from '../components/OfferRequestList';
import Broadcast from '../assets/img/icons/Broadcast'
import { decryptData } from '../library/Encryption'
import Copy from '../assets/img/icons/Copy'
import Delete from '../assets/img/icons/DeleteGray'
import CouponAddPopup from '../components/CouponAddPopUp';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Loader from '../components/Loader'


const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // ------- call API from functions and response from redux start ---------
  // vendor dashboard start
  const vendorDashboard = (dashboard) => dispatch(_vendorDashboard(dashboard));
  const vendorDashboardApiResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorDashboardResponse);
  // vendor dashboard ends

  // category list start
  const categoryList = () => dispatch(_vendorCategoryList());
  const categoryListApiResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorCategoryListResponse);
  // category list ends

  //delete product response
  const deleteProductResponse = useSelector((RootReducer) => RootReducer.ProductReducer.deleteProductResponse);

  // Message list start
  const listProductMessageList = () => dispatch(_vendorListProductMessageList());
  const listProductMessageListResponse = useSelector((RootReducer) => RootReducer.VendorReducer.listProductMessageListResponse);
  // Message list ends

  // list Schedule start
  const listSchedule = () => dispatch(_listSchedule());
  const listScheduleResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorListScheduleResponse);
  // list Schedule ends

  // List offer requests starts
  const listOfferRequests = (params) => dispatch(_listOfferRequests(params));
  const listOfferRequestResponse = useSelector((RootReducer) => RootReducer.VendorReducer.listOfferRequestsResponse);
  // List offer requests ends

  // notification check start
  const notificationCheck = () => dispatch(_vendorNotificationAvailable());
  // notification check ends


  // Product list start
  const listProducts = (details) => dispatch(_listProductToApp(details));
  const listProductToAppResponse = useSelector((RootReducer) => RootReducer.ProductReducer.listProductToAppResponse);
  // Product list ends

  // Product list start
  const cancelSchedule = (details) => dispatch(_cancelSchedule(details));
  const cancelScheduleResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorCancelScheduleResponse);
  // Product list ends

  // Product list start
  const listScheduleTime = (details) => dispatch(_scheduleTimeList(details));
  const listScheduleTimeResponse = useSelector((RootReducer) => RootReducer.ProductReducer.scheduleTimeListResponse);
  // Product list ends

  const vendorProfileResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorProfileResponse);

  // vendor subscription info Details start
  const subscriptionInfo = () => dispatch(_profileSubscriptionInfo());
  const subscriptionInfoResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.subscriptionInfoApiResponse
  );

  // coupon list start
  const couponList = () => dispatch(_vendorCouponList());
  const couponListApiResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorCouponListResponse);
  // coupon list ends

  const deleteCoupon = (params) => dispatch(_vendorDeleteCoupon(params));
  const couponDeleteApiResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorDeleteCouponResponse);


  const intitalVendorDashboardParams = {
    filter_by: 0,
    page: 1,
    search_key: "",
    category_id: 0,
    prod_type: 1,
    type: 1
  };

  const intitalVendorScheduleParams = {
    p_PageSize: 15,
    p_PageNumber: 1,
  };

  const initialOfferRequestParams = {
    offerTypeId: null,
    subCategoryId: null,
    searchTxt:"",
    p_PageNumber: 1,
    p_PageSize: 4
  };

  const copyTooltip = <Tooltip id="copy-tooltip">Copy</Tooltip>;
  const deleteTooltip = <Tooltip id="delete-tooltip">Delete</Tooltip>;
  const [showLoader, setLoader] = useState(false)
  const [vendorDashboardParams, setVendorDashboardParams] = useState(intitalVendorDashboardParams)
  const [vendorScheduleParams, setVendorScheduleParams] = useState(intitalVendorScheduleParams)
  const [offerRequestParams, setOfferRequestParams] = useState(initialOfferRequestParams);
  const [vendorDashboardResponse, setVendorDashboardResponse] = useState([])
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [messagesMaster, setMessagesMaster] = useState([])
  const [listproductshow, Setlistproductshow] = useState(false)
  const [broadcastProductshow, SetBroadcastProductshow] = useState(false)
  const [checkedItems, setCheckedItems] = useState([])
  const [listproductBtnshow, setListproductBtnshow] = useState(true)
  const [listBroadcastBtnshow, setlistBroadcastBtnshow] = useState(true)
  const [productListing, setProductListing] = useState('')
  const [listType, setListType] = useState('current')
  const [verificationInfo, setVerificationInfo] = useState([])
  const [showVerificationModal, setShowVerificationModal] = useState(false)
  const [showCoupon, setShowCoupon] = useState(false)
  const [couponListLoad, setCouponListLoad] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [modalConfirmClicked, setModalConfirmClicked] = useState(false)
  const [isClickdedDoneListMessage, seIsClickdedDoneListMessage] = useState(false)
  const [verifyType, setVerifyType] = useState("")
  const [proxyAddress, setProxyAddress] = useState({
    type: { value: 1, label: "Default Address" },
    zip_codes: [],
    proxy_details: []

  })
  const [showSchedule, setShowSchedule] = useState(false)
  const [eventKey, setEventKey] = useState('')
  const [scheduleList, setScheduleList] = useState([])
  const [scheduleTimeList, setScheduleTimeList] = useState([])
  const [offerList, setOfferList] = useState([])
  const [scheduledata, setScheduledata] = useState({
    start_date: new Date(),
    end_date: new Date(),
    occurrence: 0,
    value: [],
    date: 0,
    time: "",
    startTime: "",
    endTime: "",
    timeZone: ""
  })
  const [currentPlan, setCurrentPlan] = useState(0)
  const options = [
    { value: 0, label: 'All Items' },
    { value: 1, label: 'Available items' },
    { value: 2, label: 'Out of Stock items' },
    { value: 3, label: 'Nationwide' }
  ]
  const [couponListData, setCouponListData] = useState([])
  const [couponId, setcouponId] = useState("")

  const getChat = document.getElementById('ze-snippet')

  // ------- useEffect start -------
  // Calling Dashboard api 
  useEffect(() => {
    setLoader(true)
    vendorDashboard(vendorDashboardParams)
  }, [vendorDashboardParams])

  useEffect(() => {
    if (broadcastProductshow === constants.STATUS_TRUE || listproductshow === constants.STATUS_TRUE) {
      listProductMessageList();
    }
  }, [broadcastProductshow, listproductshow])

  // Calling Schedule api 
  useEffect(() => {
    listSchedule(vendorScheduleParams)
  }, [vendorScheduleParams])

  // Calling Offer Request api
  useEffect(()=>{    
    setLoader(true)
    listOfferRequests(offerRequestParams);
  },[offerRequestParams])

  // Calling category list api 
  useEffect(() => {
    notificationCheck()
    categoryList();
    subscriptionInfo()
    const script = document.createElement('script');
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=9bf5de41-7b28-4f4c-b165-7114ffab8b15';
    script.id = 'ze-snippet';
    script.async = true;
    document.head.appendChild(script);
    // return () => {
    //   // Cleanup when the component is unmounted
    //   document.head.removeChild(script);
    // };
  }, [])

  useEffect(() => {
    if (modalConfirmClicked) {
      if (couponId) {
        deleteCoupon({ couponId });
        setcouponId("");
        setModalConfirmClicked(false)
      } else {
        if (verifyType === "Bank") {
          navigate("/profile", {
            state: {
              current_tab: "bankinfo",
            },
          });
        } else {
          navigate("/profile", {
            state: {
              current_tab: "subscription",
            },
          });
        }
      }
    }
  }, [modalConfirmClicked]);

  //handle category list API response
  useEffect(() => {
    if (categoryListApiResponse && categoryListApiResponse.apiStatus) {
      if (categoryListApiResponse.apiErrorCode === constants.STATUS_200)
        if (categoryListApiResponse.apiResponse.subcategory_info) {
          let subCategoryist = [];
          categoryListApiResponse.apiResponse.subcategory_info.map((subcategory) => {
            subCategoryist.push({
                  category_id:subcategory.category_id,
                  subcategory_id: subcategory.subcategory_id,
                  subcategory_name: subcategory.subcategory_name,
                });              
            }
          );
          setSubCategories(subCategoryist);
        }

        if (categoryListApiResponse.apiResponse.category_info) {
          let categoryArray = [{ value: 0, label: "All" }]
          categoryListApiResponse.apiResponse.category_info.map((category) => {
            categoryArray.push({ value: category.category_id, label: category.category_name })
          })
          setCategories(categoryArray)
        }
        else
          toast.error(categoryListApiResponse.apiMessage)
    }
  }, [categoryListApiResponse])

  //handle category list API response
  useEffect(() => {
    if (listScheduleTimeResponse && listScheduleTimeResponse.apiStatus) {
      if (listScheduleTimeResponse.apiErrorCode === constants.STATUS_200)
        if (listScheduleTimeResponse.apiResponse.time_slots) {
          let timeArray = []
          listScheduleTimeResponse.apiResponse.time_slots.map((time) => {
            timeArray.push({ value: { start: time.timeslot_from, end: time.timeslot_to, meridiem: time.meridiem }, label: `${time.timeslot_from}${time.meridiem} - ${time.timeslot_to}${time.meridiem}` })
          })
          setScheduleTimeList(timeArray)
        }
        else
          toast.error(listScheduleTimeResponse.apiMessage)
    }
  }, [listScheduleTimeResponse])

 // Handle list Offer Request Api Response
  useEffect(() => {
    if (listOfferRequestResponse && listOfferRequestResponse.apiStatus) {
      //alert(JSON.stringify(listOfferRequestResponse))
      if (listOfferRequestResponse.apiErrorCode === constants.STATUS_200)
      {
        if (listOfferRequestResponse.apiResponse) {        
          let offerLists = [];
          listOfferRequestResponse.apiResponse.offerRequestList.map((item) => {
            offerLists.push(
              { requestId: item.requestId,
                offerTypeId: item.offerTypeId,
                offerTypeName: item.offerTypeName,
                subCategoryId: item.subCategoryId,
                subCategoryName: item.subCategoryName,
                offerDescription: item.offerDescription,
                minPrice: item.minPrice,
                maxPrice: item.maxPrice,
                zipCode: item.zipCode,
                expectedTime: item.expectedTime,
                imagePath: item.imagePath
               })
          })
          //alert(JSON.stringify(offerLists))
          setOfferList(offerLists);
        }      
       
      }
      else
      {
        setOfferList([])
        //toast.error(listOfferRequestResponse.apiMessage)
      }
      setLoader(false)
    }
  }, [listOfferRequestResponse])
  
  //handle dashBoard API response
  useEffect(() => {

    if (vendorDashboardApiResponse && vendorDashboardApiResponse.apiStatus) {
      if (vendorDashboardApiResponse.apiErrorCode === constants.STATUS_200) {
        setVendorDashboardResponse(vendorDashboardApiResponse);
      }
      else if (vendorDashboardApiResponse.apiErrorCode === constants.STATUS_400 && vendorDashboardApiResponse.apiStatus) {
        setVendorDashboardResponse(vendorDashboardApiResponse);
      }
      else
        toast.error(vendorDashboardApiResponse.apiMessage)

      setLoader(false)

      resetResponse("vendorDashboardResponse")
    }
  }, [vendorDashboardApiResponse])

  //handle delete product API response
  useEffect(() => {
    if (deleteProductResponse && deleteProductResponse.apiStatus) {
      if (deleteProductResponse.apiErrorCode === constants.STATUS_200) {
        vendorDashboard(vendorDashboardParams)
        toast.success(deleteProductResponse.apiMessage)
        resetResponse("deleteProduct")
      } else {
        toast.error(deleteProductResponse.apiMessage)
        resetResponse("deleteProduct")
      }
    }
  }, [deleteProductResponse])

  //handle List your product message list API response
  useEffect(() => {
    if (listProductMessageListResponse && listProductMessageListResponse.apiStatus) {
      if (listProductMessageListResponse.apiErrorCode === constants.STATUS_200)
        if (listProductMessageListResponse.apiResponse.messages_info) {
          setMessagesMaster(listProductMessageListResponse.apiResponse.messages_info)
        }
      vendorDashboard(vendorDashboardParams)
    }
  }, [listProductMessageListResponse])

  //handle List your coupon list API response
  useEffect(() => {
    if (couponListApiResponse && couponListApiResponse.apiStatus) {
      setCouponListLoad(false)
      if (couponListApiResponse.apiErrorCode === constants.STATUS_200) {
        if (couponListApiResponse.apiResponse
        ) {
          setCouponListData(couponListApiResponse.apiResponse
          )
        }
      } else setCouponListData([])
    }
  }, [couponListApiResponse])

  //handle delete coupon API response
  useEffect(() => {
    if (couponDeleteApiResponse && couponDeleteApiResponse.apiStatus) {
      if (couponDeleteApiResponse.apiErrorCode === constants.STATUS_200) {
        toast.success(couponDeleteApiResponse.apiMessage)
        resetResponse("vendorDeleteCoupon")
        couponList()
      } else {
        toast.error(couponDeleteApiResponse.apiMessage)
        resetResponse("vendorDeleteCoupon")
      }
    }
  }, [couponDeleteApiResponse])



  //handle List product to APP API response
  useEffect(() => {
    if (listProductToAppResponse && listProductToAppResponse.apiStatus) {
      if (listProductToAppResponse.apiErrorCode === constants.STATUS_200) {
        toast.success(listProductToAppResponse.apiMessage)
        seIsClickdedDoneListMessage(true)
        handleClose();
        setProxyAddress((proxyAddress) => ({
          ...proxyAddress,
          type: { value: 1, label: "Default Address" },
          zip_codes: [],
          proxy_details: []
        }));
        handleBroadcastProductPopUpClose();
        setScheduledata((scheduledata) => ({
          ...scheduledata,
          value: [],
          occurrence: 0,
          start_date: new Date(),
          end_date: new Date(),
          time: "",
          startTime: "",
          endTime: "",
          timeZone: ""
        }));
        setShowSchedule(false)
        setListType('current')
        handleTabChanage('current')
        listSchedule(vendorScheduleParams)
      }
      else {
        toast.error(listProductToAppResponse.apiMessage)

      }

      resetResponse("listProductToAppResponse")

    }
  }, [listProductToAppResponse])


  // Disabling List Product btn 
  useEffect(() => {
    if (checkedItems.length) {
      setListproductBtnshow(false)
      setlistBroadcastBtnshow(false)
    }
    else {
      setListproductBtnshow(true)
      setlistBroadcastBtnshow(true)
    }
  }, [checkedItems])

  // Disabling List Product btn 
  useEffect(() => {
    let response = "";
    if (vendorDashboardResponse.apiStatus && vendorDashboardResponse.apiResponse) {
      if (vendorDashboardResponse.apiResponse.dashboard_info) {
        response = vendorDashboardResponse.apiResponse.dashboard_info.map((product, index) => {
          return (
            <Product handleCheckBoxChange={handleCheckBoxChange} img={product.thumbnail_image ? product.thumbnail_image : NoImage} id={product?.product_id}
              title={product?.prod_name} price={product.prod_amt === 0 ? 0 : parseFloat(product?.prod_amt.replace(/,/g, '')).toFixed(2)} quantity={product?.prod_qty}
              key={index} isNationWide={product.is_nation_wide === 0 ? constants.STATUS_FALSE : constants.STATUS_TRUE} productType={vendorDashboardParams.type}
              posted_date={product?.posted_date} validTill={product?.valid_till} valid_to={product?.valid_to}
              categoryId={product?.category_id}
            />)
        })
      } else if (!vendorDashboardResponse.apiResponse.dashboard_info) {
        response = (
          <Col className="text-center mt-5">{Strings.Dashboard.NoProduct}</Col>
        )
      }
    }
    setProductListing(response)
  }, [vendorDashboardResponse, checkedItems])


  useEffect(() => {
    if (vendorProfileResponse && vendorProfileResponse.apiStatus) {
      if (
        vendorProfileResponse.apiErrorCode == constants.STATUS_200 &&
        vendorProfileResponse.apiResponse &&
        vendorProfileResponse.apiResponse.verification_info
      ) {
        setVerificationInfo(
          vendorProfileResponse.apiResponse.verification_info
        );
        if (
          vendorProfileResponse.apiResponse.verification_info &&
          vendorProfileResponse.apiResponse.verification_info.subscribed === constants.STATUS_FALSE &&
          vendorProfileResponse.apiResponse.verification_info.is_first_subscription === constants.STATUS_FALSE
        ) {
          navigate("/profile", {
            state: {
              current_tab: "subscription",
            },
          });
          if (getChat !== null) {
            window.location.reload()
          }

        }
      }
    }
  }, [vendorProfileResponse]);

  //handle category list API response
  useEffect(() => {
    if (cancelScheduleResponse && cancelScheduleResponse.apiStatus) {
      if (cancelScheduleResponse.apiErrorCode === constants.STATUS_200) {
        toast.success(cancelScheduleResponse.apiMessage)
        listSchedule()
      }
      else {
        toast.error(cancelScheduleResponse.apiMessage)
      }
      resetResponse("cancelSchedule")
    }
  }, [cancelScheduleResponse])

  useEffect(() => {
    if (listScheduleResponse && listScheduleResponse.apiStatus) {
      if (
        listScheduleResponse.apiErrorCode === constants.STATUS_200 &&
        listScheduleResponse.apiResponse
      ) {
        let schedule_data = [];
        if (
          listScheduleResponse.apiResponse.scheduled_ntfn_list &&
          listScheduleResponse.apiResponse.scheduled_ntfn_list.length > 0
        ) {
          listScheduleResponse.apiResponse.scheduled_ntfn_list.map((data) => {
            let id = data.scheduleid;
            const filteredProductItems =
              listScheduleResponse.apiResponse.scheduled_ntfn_products.filter(
                (data) => data.scheduleid === id
              );
            const filteredAddress =
              listScheduleResponse.apiResponse.scheduled_ntfn_address.filter(
                (data) => data.scheduleid === id
              );
            let product = [];
            filteredProductItems.map((data) => {
              product.push({
                product_name: data.productname,
                product_id: data.productid,
                product_image: data.thumbnail_image,
              });
            });
            let proxy_zipcode = []
            if (filteredAddress && filteredAddress.length > 0) {
              if (filteredAddress[0].notificationtype === constants.STATUS_2) {
                filteredAddress.map((data) => {
                  proxy_zipcode.push(data?.proxy_zipcode);
                });
              }
            }
            schedule_data.push({
              scheduleid: data.scheduleid,
              startdate: data.startdate,
              occurrence: 3,
              enddate: data.enddate,
              products: product,
              date: data.day,
              timeslot: data?.timeslot,
              zipcodes: proxy_zipcode,
              address: filteredAddress[0].address ? filteredAddress[0].address : "",
              city: filteredAddress[0].city ? filteredAddress[0].city : "",
              country: filteredAddress[0].country ? filteredAddress[0].country : "",
              state: filteredAddress[0].state ? filteredAddress[0].state : "",
              zip: filteredAddress[0].zip ? filteredAddress[0].zip : "",
              d_address1: filteredAddress[0].d_address1 ? filteredAddress[0].d_address1 : "",
              d_city: filteredAddress[0].d_city ? filteredAddress[0].d_city : "",
              d_country: filteredAddress[0].d_country ? filteredAddress[0].d_country : "",
              d_state: filteredAddress[0].d_state ? filteredAddress[0].d_state : "",
              d_zip: filteredAddress[0].d_zip ? filteredAddress[0].d_zip : "",
              notificationtype: filteredAddress[0].notificationtype,
            });
          });
        }
        setScheduleList(schedule_data);
      } else {
        setScheduleList([]);
      }
    }
  }, [listScheduleResponse]);

  // handling subscription info Response
  useEffect(() => {
    if (
      subscriptionInfoResponse &&
      subscriptionInfoResponse.apiStatus &&
      subscriptionInfoResponse.apiErrorCode === constants.STATUS_200
    ) {
      if (
        subscriptionInfoResponse.apiResponse &&
        subscriptionInfoResponse.apiResponse.subscription_info
      ) {
        setCurrentPlan(subscriptionInfoResponse.apiResponse.subscription_info?.subscription_type)
      }
    }
  }, [subscriptionInfoResponse]);

  const handleClose = () => Setlistproductshow(false);
  const handleBroadcastProductPopUpClose = () => SetBroadcastProductshow(false);

  //handle search changes
  const searchTextChange = (searchText, filterValue) => {
    setVendorDashboardParams(vendorDashboardParams => ({ ...vendorDashboardParams, search_key: searchText, filter_by: filterValue, p_PageNumber: 1, page: 1 }))
  }

  // handle category list change
  const handleChanges = (event) => {
    setVendorDashboardParams(vendorDashboardParams => ({ ...vendorDashboardParams, category_id: event.value, p_PageNumber: 1, page: 1 }))
  }

  // page change and state change
  const pageChange = (page, type) => {
    if (page) {
      if (type === "schedule")
        setVendorScheduleParams(vendorScheduleParams => ({ ...vendorScheduleParams, p_PageNumber: page }))
      else if (type === "requests")
        setOfferRequestParams(offerRequestParams => ({...offerRequestParams, p_PageNumber: page }))
      else
        setVendorDashboardParams(vendorDashboardParams => ({ ...vendorDashboardParams, page: page }))
    }
  }
  const verificationModal = (msg) => {
    setShowVerificationModal(true)
    setModalMessage(msg)
  }
  const handleConfirmModal = () => {
    setShowVerificationModal(false)
    setModalConfirmClicked(true)
  }
  // change tab
  const handleTabChanage = (key) => {
    setVendorDashboardResponse([])
    setCheckedItems([])
    setListType(key)
    let type = 1;
    if (key === 'current') {
      type = 1;
      setEventKey('current')
    } else if (key === 'upcoming') {
      type = 2
      setEventKey('upcoming')
    } else if (key === "schedules") {
      setOfferList([])
      listSchedule(vendorScheduleParams)
      setEventKey('schedules')
    }
    else if(key === "requests")
    {
      listOfferRequests(initialOfferRequestParams)
      setEventKey('requests')
    }
    if (key === "coupon") {
      setCouponListLoad(true)
      couponList()
    }

    setVendorDashboardParams(vendorDashboardParams => ({ ...vendorDashboardParams, p_PageNumber: 1, type: type, page: 1, search_key: "", category_id: 0, filter_by: 0, prod_type: 1 }))
  }


  const resetResponse = (action) => {
    dispatch(
      {
        type: action, apiStatus: false, apiErrorCode: 0, apiResponse: {}, apiMessage: ""
      }
    );
  }

  // redirecting to add product
  const goToAddProducts = () => {
    if (verificationInfo && !verificationInfo.subscribed) {
      verificationModal(alerts.PLEASE_SUBSCRIBE_TO_ADD);
      setVerifyType("Subscription");
    } else {
      navigate("/addproduct");
      setVerifyType("");
      // window.location.reload()
    }
  };

  //checkbox changes
  const handleCheckBoxChange = (e) => {
    const isChecked = e.target.checked
    if (isChecked === true) {
      setCheckedItems([...checkedItems, e.target.value])
    }
    else if (isChecked === false) {
      var array = [...checkedItems]
      var index = array.indexOf(e.target.value)
      if (index !== -1) {
        array.splice(index, 1)
        setCheckedItems(array)
      }
    }
  }

  //List product call
  const submitListProduct = (messageId) => {
    const data = {
      product_array: checkedItems,
      messageId: messageId,
      type: 1,
      proxy_type: proxyAddress.type?.value,
      zip_codes: proxyAddress.zip_codes
    }
    listProducts(data);
  }

  //Broadcast product call
  const submitBroadcastProduct = (messageId) => {
    const data = {
      product_array: checkedItems,
      messageId: messageId,
      type: 2,
      proxy_type: proxyAddress.type?.value,
      zip_codes: proxyAddress.zip_codes
    }
    listProducts(data);
  }

  const handleListProductBtn = (type) => {
    if (verificationInfo && !verificationInfo.bank_verified) {
      if (type === "listProduct") {
        verificationModal(alerts.PLEASE_VERIFY_BANK);
      } else {
        verificationModal(alerts.PLEASE_VERIFY_BANK_BROADCAST);
      }
      setVerifyType("Bank");
    } else if (verificationInfo && !verificationInfo.subscribed) {
      if (type === "listProduct") {
        verificationModal(alerts.PLEASE_SUBSCRIBE_LIST);
      } else {
        verificationModal(alerts.PLEASE_SUBSCRIBE_LIST_BROADCAST);
      }
      setVerifyType("Subscription");
    } else if (verificationInfo && verificationInfo.subscribed && verificationInfo.bank_verified) {
      if (type === "listProduct") {
        // Setlistproductshow(true);
        // listScheduleTime();
        navigate("/broadcast", {
          state: {
            checkedItems: checkedItems,
            type:1
          },
        });
      } else {
        navigate("/broadcast", {
          state: {
            checkedItems: checkedItems,
            type:2
          },
        });
        // SetBroadcastProductshow(true);
        // listScheduleTime();
      }
      setVerifyType("");
    }

  };

  const handleWeekChange = (val) => {
    setScheduledata((scheduledata) => ({
      ...scheduledata,
      value: WeekChange(val, scheduledata),
    }));
  };


  const submitScheduleList = (messageId) => {
    const data = {
      product_array: checkedItems,
      messageId: messageId,
      type: 1,
      isSchedule: 1,
      startdate: scheduledata.start_date,
      enddate: scheduledata.end_date,
      date: scheduledata.date,
      proxy_type: proxyAddress.type?.value,
      timeslot: scheduledata?.time,
      startTime: scheduledata?.startTime,
      endTime: scheduledata?.endTime,
      timeZone: scheduledata?.timeZone,
      proxy_details: proxyAddress?.proxy_details,
      zip_codes: proxyAddress.zip_codes
    }
    listProducts(data);
  }

  const submitScheduleBroadcast = (messageId) => {
    const data = {
      product_array: checkedItems,
      messageId: messageId,
      type: 2,
      isSchedule: 1,
      startdate: scheduledata.start_date,
      enddate: scheduledata.end_date,
      date: scheduledata.date,
      proxy_type: proxyAddress.type?.value,
      timeslot: scheduledata?.time,
      startTime: scheduledata?.startTime,
      endTime: scheduledata?.endTime,
      timeZone: scheduledata?.timeZone,
      proxy_details: proxyAddress?.proxy_details,
      zip_codes: proxyAddress.zip_codes
    }
    listProducts(data);
  }

  const handleCancelSubscription = (id) => {
    cancelSchedule({
      scheduleId: id
    })
  }

  const handleCouponDelete = (id) => {
    verificationModal(alerts.DELETE_CONFIRMATION)
    setcouponId(id)
  }

  const searchOfferRequests = (categoryId, subCategoryId, searchText) => {
   //alert(categoryId);
   //alert(subCategoryId);
   //alert(searchText);
    //setOfferList([])
    setOfferRequestParams((prevValues) => ({
      ...prevValues,
      offerTypeId: categoryId,
      subCategoryId: subCategoryId,
      searchTxt:searchText,
      p_PageNumber: 1,
      p_PageSize: 4
    }));
  };

  // handle to copy the number to clipboard
  const handleCopyPhoneNo = async (Code) => {
    // Check if there is an extracted number
    if (Code) {
      try {
        await navigator.clipboard.writeText(Code);
        toast.success('Coupon code copied to clipboard');
      } catch (err) {
        console.error('Failed to copy number to clipboard', err);
      }
    }
  };

  
  return (
    <InnerLayout>
      {showLoader && <Loader />}
      <Col className="action-wrap">
        <Row>
          <Col lg="3" className="d-lg-flex align-items-lg-center">
            <h1>{Strings.Dashboard.Title}</h1>
          </Col>
          <Col lg="9" className="text-lg-end">
            <Button variant="primary" onClick={() => goToAddProducts()}>
              <Add />
              {Strings.Dashboard.Btn2}
            </Button>
          </Col>
        </Row>
      </Col>      
      <Row className="search-wrap">
        <Col xl="5" lg="6" md="6">
        {eventKey !== "requests" ?(
          <SearchSelect
            placeholder="All Items"
            vendorDashboardParams={vendorDashboardParams}
            listType={listType}
            options={options}
            onClick={searchTextChange}
          />):("")
        }
        </Col>
        <Col xl="7" lg="6" md="6" className="d-lg-flex justify-content-lg-end">
          {eventKey !== "schedules"  && eventKey !== "requests" ? (
            <CustSelect
              placeholder="All"
              options={categories}
              onChange={(e) => {
                handleChanges(e);
              }}
            />
          ) : (
            ""
          )}
        </Col>
      </Row>

      <Col className="position-relative tab-wrap">
        {listType === "current" && (
          <Button
            disabled={listBroadcastBtnshow || listType !== "current"}
            variant="primary"
            className="btn-notify"
            onClick={() => handleListProductBtn()}
          >
            <Broadcast />
            {Strings.Dashboard.Btn4}
          </Button>
        )}
        {listType === "upcoming" && (
          <Button
            disabled={listproductBtnshow || listType === "current"}
            variant="primary"
            className="btn-notify"
            onClick={() => handleListProductBtn("listProduct")}
          >
            <Broadcast />
            {Strings.Dashboard.Btn5}
          </Button>
        )}
        {/* <Button variant="primary" className="btn-notify">
          <Broadcast />
          {Strings.Dashboard.Btn4}
        </Button> */}
        <Tabs
          defaultActiveKey="current"
          id="uncontrolled-tab-example"
          className="mb-3"
          activeKey={listType}
          onSelect={handleTabChanage}
        >
          <Tab eventKey="current" title={Strings.Dashboard.Tab1}>
            <Row>{listType == "current" ? productListing : ""}</Row>
          </Tab>
          <Tab eventKey="upcoming" title={Strings.Dashboard.Tab2}>
            <Row>{listType == "upcoming" ? productListing : ""}</Row>
          </Tab>
          <Tab eventKey="schedules" title={Strings.Dashboard.Tab3}>
            {scheduleList && scheduleList.length > 0 ? (
              scheduleList.map((data, key) => {
                return (
                  <SchedulesView
                    key={[key]}
                    Data={data}
                    handleCancelSubscription={handleCancelSubscription}
                    showSchedule={showSchedule}
                    value={scheduledata.value}
                  />
                );
              })
            ) : (
              <Col className="text-center mt-5">
                {Strings.Dashboard.NoSchedule}
              </Col>
            )}
          </Tab>
          <Tab eventKey="requests" title="Offer Requests">
            <OfferRequestList
              OfferList={offerList}
              CategoryList={categories}
              SubCategoryList={subCategories}
              searchOfferRequests={searchOfferRequests}
              TabChange={eventKey}
            ></OfferRequestList>  
          </Tab>
          {/* <Tab eventKey="coupon" title={Strings.Dashboard.Tab4}>
            <Col className="action-wrap border-0">
              <Row>
                <Col lg="3" className="d-lg-flex align-items-lg-center">
                  <h1>Coupon</h1>
                </Col>
                <Col lg="9" className="text-lg-end">
                  <Button variant="primary" onClick={setShowCoupon}>
                    <Add />
                    {Strings.Dashboard.Btn6}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Table responsive>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Coupon Name</th>
                  <th>Discount Type / Value</th>
                  <th>Valid From</th>
                  <th>Valid To</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {couponListData && !couponListLoad && couponListData.length > 0
                  ? couponListData.map((data, key) => {
                      return (
                        <tr>
                          <td>{key+1}</td>
                          <td>{data?.coupon_code}</td>
                          <td>Flat : {data?.discount_type === constants.STATUS_2 && '$'} {data?.discount_percentage} {data?.discount_type === constants.STATUS_1 && '%'}</td>
                          <td>{dateToMMDDYYYSlashFormat(data?.valid_from)}</td>
                          <td>{dateToMMDDYYYSlashFormat(data?.valid_to)}</td>
                          <td className="sub-status">{data?.status ==='Active' ? <span className="success">Active</span> :<span className="failed">Expired</span>}</td>
                          <td>

                            <OverlayTrigger
                              placement="top"
                              overlay={copyTooltip}
                            >
                              <Button variant="link" onClick={() => { handleCopyPhoneNo(data?.coupon_code) }}>
                                <Copy />
                              </Button>
                            </OverlayTrigger>

                            <OverlayTrigger
                              placement="top"
                              overlay={deleteTooltip}
                            >
                              <Button variant="link" onClick={() => { handleCouponDelete(data?.couponid) }}>
                                <Delete />
                              </Button>
                            </OverlayTrigger>
                            
                          </td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </Table>
            {couponListLoad ? (
              <div className="text-center mt-5">
                <span
                  class="spinner-border"
                  style={{ color: "#94DA0A" }}
                ></span>
              </div>
            ) : couponListData && couponListData.length > 0 ? (
              ""
            ) : (
              <Col className="text-center mt-5">
                {Strings.Dashboard.NoCoupon}
              </Col>
            )}
          </Tab> */}
        </Tabs>
      </Col>
      {listType !== "schedules"  && listType !== "requests" &&
        vendorDashboardResponse.apiStatus &&
        vendorDashboardResponse.apiResponse &&
        vendorDashboardResponse.apiResponse.pagination_info &&
        vendorDashboardResponse.apiResponse.pagination_info.total_records ? (
        <Col className="d-flex justify-content-center paginate-wrap mt-4">
          <span className="records">
            {Strings.Pagination.Label} &nbsp;
            {(vendorDashboardParams.page - 1) *
              vendorDashboardResponse.apiResponse.pagination_info.per_page +
              1}
            &nbsp; {Strings.Pagination.Label1} &nbsp;
            {(vendorDashboardParams.page - 1) *
              vendorDashboardResponse.apiResponse.pagination_info.per_page +
              vendorDashboardResponse.apiResponse.dashboard_info.length}
            &nbsp; {Strings.Pagination.Label2} &nbsp;
            {vendorDashboardResponse.apiResponse.pagination_info.total_records}
            &nbsp; {Strings.Pagination.Label3}
          </span>

          <Paginate
            totalRecords={
              vendorDashboardResponse.apiResponse.pagination_info.total_records
            }
            currentPage={
              vendorDashboardResponse.apiResponse.pagination_info.current_page
            }
            perPage={
              vendorDashboardResponse.apiResponse.pagination_info.per_page
            }
            onClick={(page) => {
              pageChange(page);
            }}
          />
        </Col>
      ) : (
        ""
      )}

      {listType === "schedules" &&
        listScheduleResponse.apiStatus &&
        listScheduleResponse.apiResponse &&
        listScheduleResponse.apiResponse.total_records ? (
        <Col className="d-flex justify-content-center paginate-wrap mt-4">
          <span className="records">
            {Strings.Pagination.Label} &nbsp;
            {(vendorScheduleParams.p_PageNumber - 1) *
              listScheduleResponse.apiResponse.per_page +
              1}
            &nbsp; {Strings.Pagination.Label1} &nbsp;
            {(vendorScheduleParams.p_PageNumber - 1) *
              listScheduleResponse.apiResponse.per_page +
              listScheduleResponse.apiResponse.scheduled_ntfn_list.length}
            &nbsp; {Strings.Pagination.Label2} &nbsp;
            {listScheduleResponse.apiResponse.total_records}
            &nbsp; {Strings.Pagination.Label3}
          </span>

          <Paginate
            totalRecords={listScheduleResponse.apiResponse.total_records}
            currentPage={listScheduleResponse.apiResponse.current_page}
            perPage={listScheduleResponse.apiResponse.per_page}
            onClick={(page) => {
              pageChange(page, "schedule");
            }}
          />
        </Col>
      ) : (
        ""
      )}

    {listType === "requests" &&
        listOfferRequestResponse.apiStatus &&
        listOfferRequestResponse.apiResponse &&
        listOfferRequestResponse.apiResponse.pagination_info &&
        listOfferRequestResponse.apiResponse.pagination_info.total_records ? (
        <Col className="d-flex justify-content-center paginate-wrap mt-4">
          <span className="records">
            {Strings.Pagination.Label} &nbsp;
            {(offerRequestParams.p_PageNumber - 1) *
              listOfferRequestResponse.apiResponse.pagination_info.per_page +
              1}
            &nbsp; {Strings.Pagination.Label1} &nbsp;
            {(offerRequestParams.p_PageNumber - 1) *
              listOfferRequestResponse.apiResponse.pagination_info.per_page +
              listOfferRequestResponse.apiResponse.offerRequestList.length}
            &nbsp; {Strings.Pagination.Label2} &nbsp;
            {listOfferRequestResponse.apiResponse.pagination_info.total_records}
            &nbsp; {Strings.Pagination.Label3}
          </span>

          <Paginate
            totalRecords={
              listOfferRequestResponse.apiResponse.pagination_info.total_records
            }
            currentPage={
              listOfferRequestResponse.apiResponse.pagination_info.current_page
            }
            perPage={
              listOfferRequestResponse.apiResponse.pagination_info.per_page
            }
            onClick={(page) => {
              pageChange(page,"requests");
            }}
          />
        </Col>
      ) : (
        ""
      )}

      {/* List Product Popup */}
      <ListProductPopUp
        messagesMaster={messagesMaster}
        listproductshow={listproductshow}
        submitListProduct={submitListProduct}
        handleClose={handleClose}
        noOfProducts={checkedItems.length}
        listproducHide={handleClose}
        scheduledata={scheduledata}
        setScheduledata={setScheduledata}
        submitScheduleList={submitScheduleList}
        handleWeekChange={handleWeekChange}
        showSchedule={showSchedule}
        setShowSchedule={setShowSchedule}
        proxyAddress={proxyAddress}
        setProxyAddress={setProxyAddress}
        timeList={scheduleTimeList}
        isClickdedDoneListMessage={isClickdedDoneListMessage}
        seIsClickdedDoneListMessage={seIsClickdedDoneListMessage}
        currentPlan={currentPlan}
      />
      <BroadcastProductPopUp
        messagesMaster={messagesMaster}
        listproductshow={broadcastProductshow}
        submitListProduct={submitBroadcastProduct}
        handleClose={handleBroadcastProductPopUpClose}
        noOfProducts={checkedItems.length}
        listproducHide={handleBroadcastProductPopUpClose}
        scheduledata={scheduledata}
        setScheduledata={setScheduledata}
        submitScheduleList={submitScheduleBroadcast}
        handleWeekChange={handleWeekChange}
        showSchedule={showSchedule}
        setShowSchedule={setShowSchedule}
        proxyAddress={proxyAddress}
        setProxyAddress={setProxyAddress}
        timeList={scheduleTimeList}
        isClickdedDoneListMessage={isClickdedDoneListMessage}
        seIsClickdedDoneListMessage={seIsClickdedDoneListMessage}
        currentPlan={currentPlan}
      />

      <Modal
        show={showVerificationModal}
        onHide={() => setShowVerificationModal(false)}
        size="l"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <h5 className="text-center">{modalMessage}</h5>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="outline-primary"
            onClick={() => setShowVerificationModal(false)}
          >
            {Strings.Profile.PaymentMethod.Btn}
          </Button>
          <Button variant="primary" onClick={handleConfirmModal}>
            {Strings.Profile.PaymentMethod.Btn3}
          </Button>
        </Modal.Footer>
      </Modal>
      <CouponAddPopup
        show={showCoupon}
        setShow={setShowCoupon}
        setCouponListLoad={setCouponListLoad}
      />
      {/* <Modal show={showCoupon} onHide={setShowCoupon} size="lg">
        <Modal.Header>
          <Modal.Title>Add New Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Row>
            <Col xl={6} lang={6}>
              <Input
                label="Coupon Name"
                id="CouponName"
                name="CouponName"
                type="text"
              />
            </Col>
            <Col xl={6} lang={6}>
              <Row className="pe-2">
                <Col className="pe-0 border-right-radius-none">
                  <CustSelectLabel
                    options={Percentage}
                    label="Discount Type"
                    onChange={(e) => e}
                    name="message"
                    placeholder="Percentage Discount"
                  />
                </Col>
                <Col sm={3} className="select-child no-margin ps-0">
                  <Input
                    //label="Coupon Name"
                    id="CouponName"
                    name="CouponName"
                    type="text"
                  />
                </Col>
              </Row>
            </Col>
            <Col xl={6} lg={6}>
              <div className="date-picker">
                <label>Valid From</label>
                <DatePicker maxDate={new Date()} onChange={(date) => date} />
              </div>
            </Col>
            <Col xl={6} lg={6}>
              <div className="date-picker">
                <label>Valid To</label>
                <DatePicker maxDate={new Date()} onChange={(date) => date} />
              </div>
            </Col>
           
            <Col className="mt-3 position-relative">
              <small className="character-count">1000/1000</small>
              <CustTextArea
                label={Strings.Profile.BusinessInfo.Label3}
                name="business_description"
                maxLength="500"
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Generate Coupon
          </Button>
        </Modal.Footer>
      </Modal> */}
    </InnerLayout>
  );
}
export default Dashboard;
